import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { LocaleService } from '@core/services/locale/locale.service';
import { PermissionParserService } from '@core/services/permission-parser/permission-parser.service';
import { HubDataService } from '@shared/services';
import { LanguageDataService } from 'app/modules/global-configuration/services/data/language-data.service';
import { UserDataService } from 'app/modules/users/services/data/user-data.service';
import { UserPreferenceDataService } from 'app/modules/users/services/data/user-preference-data.service';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';
import { forkJoin } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard extends KeycloakAuthGuard {
  constructor(
    protected readonly router: Router,
    protected readonly keycloak: KeycloakService,
    private userRequestService: UserDataService,
    private userPrefreencesRequestService: UserPreferenceDataService,
    private languageRequestService: LanguageDataService,
    private hubRequestService: HubDataService,
    private permissionsParser: PermissionParserService,
    private localeService: LocaleService
  ) {
    super(router, keycloak);
  }

  public async isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    // Force the user to log in if currently unauthenticated.
    if (!this.authenticated) {
      await this.keycloak.login({
        redirectUri: window.location.origin + state.url,
      });
    }
    let forkResponse;
    await forkJoin({
      userInfo: this.userRequestService.getUserInfo({
        extras: { cacheKey: 'userInfo' },
        showLoading: true,
        showMsg: true,
      }),
      userPref: this.userPrefreencesRequestService.myPreferences({
        extras: { cacheKey: 'userPref' },
        showLoading: true,
        showMsg: true,
      }),
      licensedModules: this.hubRequestService.getLicensedModules({
        extras: { cacheKey: 'licensedModules' },
        showLoading: true,
        showMsg: true,
      }),
    })
      .toPromise()
      .then((res) => {
        forkResponse = res;
        this.permissionsParser.initializeUserPermissions(res.userInfo, res.licensedModules.data);
      });
    if (forkResponse?.userPref?.data?.lang) {
      await forkJoin({
        langFile: this.languageRequestService.downloadLanguageFileAsTextResponse(forkResponse?.userPref?.data?.lang, {
          extras: { cacheKey: 'LanguageFile' + forkResponse?.userPref?.data?.lang },
          showLoading: true,
          showMsg: true,
        }),
        // lang: this.languageRequestService.getByIdOrCode(forkResponse?.userPref?.data?.lang, {
        //   extras: { cacheKey: 'userLanguage' + forkResponse?.userPref?.data?.lang },
        //   showLoading: true,
        //   showMsg: true,
        // }),
      })
        .toPromise()
        .then(
          async (lang) => {
            // console.log('lang', lang);
            await this.localeService.setLanguage(forkResponse?.userPref?.data?.languageDto, lang?.langFile);
            // this.permissionsParser.initializeUserPermissions(forkResponse.userInfo, forkResponse.licensedModules.data);
            // await this.languageRequestService.downloadMergedCsv(forkResponse?.userPref?.data?.lang);
          },
          (err) => {
            console.log(err);
            console.log('lang error');
            this.localeService.setLanguage(null, null);
          }
        )
        .finally(() => {});
    } else {
      await forkJoin({
        langFile: this.languageRequestService.getDefaultLanguage({
          extras: { cacheKey: 'defaultLanguage' },
          showLoading: true,
          showMsg: true,
        }),
        // lang: this.languageRequestService.getByIdOrCode(forkResponse?.userPref?.data?.lang, {
        //   extras: { cacheKey: 'userLanguage' + forkResponse?.userPref?.data?.lang },
        //   showLoading: true,
        //   showMsg: true,
        // }),
      })
        .toPromise()
        .then(
          async (lang) => {
            // console.log('lang', lang);
            if (lang?.langFile?.data?.code) {
              await forkJoin({
                langFile: this.languageRequestService.downloadLanguageFileAsTextResponse(lang?.langFile?.data?.code, {
                  extras: { cacheKey: 'LanguageFile' + lang?.langFile?.data?.code },
                  showLoading: true,
                  showMsg: true,
                }),
                // lang: this.languageRequestService.getByIdOrCode(forkResponse?.userPref?.data?.lang, {
                //   extras: { cacheKey: 'userLanguage' + forkResponse?.userPref?.data?.lang },
                //   showLoading: true,
                //   showMsg: true,
                // }),
              })
                .toPromise()
                .then(
                  async (langFile) => {
                    // console.log('lang', lang);
                    await this.localeService.setLanguage(lang?.langFile.data, langFile?.langFile);
                    // this.permissionsParser.initializeUserPermissions(forkResponse.userInfo, forkResponse.licensedModules.data);
                    // await this.languageRequestService.downloadMergedCsv(forkResponse?.userPref?.data?.lang);
                  },
                  (err) => {
                    console.log(err);
                    console.log('Default lang error');
                    this.localeService.setLanguage(null, null);
                  }
                )
                .finally(() => {});
            } else {
              // console.log('lang empty');
              this.localeService.setLanguage(null, null);
            }
            // this.permissionsParser.initializeUserPermissions(forkResponse.userInfo, forkResponse.licensedModules.data);
            // await this.languageRequestService.downloadMergedCsv(forkResponse?.userPref?.data?.lang);
          },
          (err) => {
            console.log(err);
            console.log('Default lang error');
            console.log('lang error');
            this.localeService.setLanguage(null, null);
            // this.localeService.setLanguage(null, null);
          }
        )
        .finally(() => {});
    }
    this.languageRequestService.downloadAllLanguagesWithFiles();
    // await this.userRequestService.getUserInfo({ extras: { cacheKey: 'userInfo' }, showLoading: true, showMsg: true }).toPromise().then(res => {

    //     this.permissionsParser.initializeUserPermissions(res);
    // });
    // await this.hubRequestService.getLicensedModules().toPromise().then(res=>{

    // })
    // Get the roles required from the route.
    const requiredRoles = route.data.roles;

    // Allow the user to to proceed if no additional roles are required to access the route.
    if (!(requiredRoles instanceof Array) || requiredRoles.length === 0) {
      return true;
    }

    // Allow the user to proceed if all the required roles are present.
    return requiredRoles.every((role) => this.roles.includes(role));
  }
}
